import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from '../header/Header';
import Footer from '../footer/Footer';
import Home from '../../pages/Home';
import Projects from '../../components/projects/Projects';
import Contacts from '../../pages/Contacts';
import ProjectPage from '../../pages/ProjectPage';
import Skills from '../../pages/Skills';

import ScrollToTop from './../../utils/ScrollToTop';

import './../../styles/main.css';



function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/project/:id' element={<ProjectPage />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/skills' element={<Skills />} />
        </Routes>
        <Footer />
      </Router>
      
    </div>
  );
}

export default App;
