import React from 'react';
import './Footer.css';

import telegram from './img/telegram.svg';
import vk from './img/vk.svg';
import gitHub from './img/gitHub.svg';
import linkedIn from './img/linkedIn.svg';

function Footer() {
  return ( 
    <footer className="footer">
        <div className="footer__container container">
          <ul className="footer__social">
            <li className="footer__social_item">
              <a href="https://t.me/lapatkina_iuliana" target="_blanc" rel="noreferrer">
                <img src={telegram} alt="" />
              </a>
            </li>
            <li className="footer__social_item">
              <a href="https://vk.com/iordis" target="_blanc" rel="noreferrer">
                <img src={vk} alt="" />
              </a>
            </li>
            <li className="footer__social_item">
              <a href="https://github.com/iuliana-lapatkina" target="_blanc" rel="noreferrer">
                <img src={gitHub} alt="" />
              </a>
            </li>
            <li className="footer__social_item">
              <a href="" target="_blanc" rel="noreferrer">
                <img src={linkedIn} alt="" />
              </a>
            </li>
          </ul>
          <span className="footer__copyright">© 2023 iulap.ru</span>
        </div>
      </footer>
   );
}

export default Footer;