import React from 'react';

function Skills() {
  return ( 
    <section className="section skills">
        <div className="container">
          <h2 className="section-title skills__title">Skills</h2>
          <ul className="skills__list">
            <li className="skills__list-item">
              <h3 className="section-subtitle">Frontend</h3>
              <p className="skills__text">
                JavaScript, TypeScript, ReactJS, Redux, HTML, CSS, NPM, BootStrap, AntdDesign, TailwindCSS, StyledComponents
              </p>
            </li>
            <li className="skills__list-item">
              <h3 className="section-subtitle">Backend</h3>
              <p className="skills__text">PHP</p>
            </li>
          </ul>
        </div>
      </section>
   );
}

export default Skills;