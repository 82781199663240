import React from 'react';
import { useParams } from 'react-router-dom';
import Project from '../components/project/Project';


function ProjectPage() {
  const { id } = useParams();

  return <Project id={id} />
}

export default ProjectPage;