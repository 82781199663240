import React from 'react';
import { NavLink } from 'react-router-dom';

function ProjectItem( {project} ) {
  return ( 
    <div className="projects__item">
      <NavLink to={`/project/${project.id}`} >
        <img
          src={project.img}
          alt={project.title}
          className="projects__item-image" />
        <h3 className="projects__item-title">{project.title}</h3>
      </NavLink>
    </div>
   );
}

export default ProjectItem;