import React from 'react';

import About from "../components/about/About";
import Projects from "../components/projects/Projects";

function Home() {
  return ( 
      <main>
        <About />
        <Projects />
      </main>
   );
}

export default Home;