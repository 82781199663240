import React from 'react';
import { NavLink } from 'react-router-dom';

import './About.css';

function About() {
  return ( 
    <section className="about">
          <div className="container about__container">
            <h2 className="about__title">
              Hi, my name is <span className="about__title--accent">Iuliana</span>
              <span className="about__title_addition">the frontend developer</span>
            </h2>
            <p className="about__text">
              with passion for learning and creating.
            </p>
            <NavLink className="about__btn" to="/cv">
              See SV
            </NavLink>
          </div>
        </section>
   );
}

export default About;