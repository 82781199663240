import React from 'react';
import { NavLink } from 'react-router-dom';
import Navigation from '../navigation/Navigation';

import './Header.css';

import sun from './img/sun.svg';
import moon from './img/moon.svg';


export default function Header () {
  return (
    <header className="header">
        <div className="container header__container">
          <NavLink className="logo" to="/">
            <h1 className="logo__title">
              <span className="logo__title--first-word">Iulap</span> portfolio
            </h1>
          </NavLink>

          <Navigation />

          <button className="dark-mode-btn">
            <img src={sun} alt="Light mode" className="dark-mode-btn__icon" />
            <img src={moon} alt="Dark mode" className="dark-mode-btn__icon" />
          </button>

          
        </div>
      </header>
  );
}
