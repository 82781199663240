import React from 'react';
import { NavLink } from 'react-router-dom';

import './Navigation.css';

function Navigation() {

  const activeLink = 'nav-link nav-link--active';
  const normalLink = 'nav-link ';

  return ( 
    <nav className="nav">
      <ul className="nav-list">
        
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? activeLink : normalLink} to="/">
            Home
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? activeLink : normalLink} to="/projects">
            Projects
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? activeLink : normalLink} to="/skills">
            Skills
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? activeLink : normalLink} to="/cv">
            CV
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className={({isActive}) => isActive ? activeLink : normalLink} to="/contacts">
            Contacts
          </NavLink>
        </li>
      </ul>
    </nav>
   );
}

export default Navigation;