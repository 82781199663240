
import blog from './ProjectsImg/blog.png';
import aviasales from './ProjectsImg/aviasales.png';
import movies from './ProjectsImg/movies.png';
import todo from './ProjectsImg/todo.png';
import search_repos from './ProjectsImg/search_repos.png';
import tochka from './ProjectsImg/tochka.png';
import cps from './ProjectsImg/cps.png';
import drons from './ProjectsImg/drons.png';

const projects = [
  {
    id: 1,
    title: 'RealWorld Blog',
    skills: 'React, Redux, Ant design',
    img: blog,
    gitHubLink: 'https://github.com/iuliana-lapatkina/blog',
    vercelLink: 'https://blog-iuliana-lapatkina.vercel.app',
  },
  {
    id: 2,
    title: 'Aviasales',
    skills: 'React, Redux, Ant design',
    img: aviasales,
    gitHubLink: 'https://github.com/iuliana-lapatkina/aviasales-app',
    vercelLink: 'https://aviasales-iuliana-lapatkina.vercel.app/',
  },
  {
    id: 3,
    title: 'Movies App',
    skills: 'React, Redux, Ant design',
    img: movies,
    gitHubLink: 'https://github.com/iuliana-lapatkina/movie-app',
    vercelLink: 'https://movie-app-iuliana-lapatkina.vercel.app/',
  },
  {
    id: 4,
    title: 'Todo List',
    skills: 'React, Redux, Ant design',
    img: todo,
    gitHubLink: 'https://github.com/iuliana-lapatkina/todo-app',
    vercelLink: 'https://todo-app-ruddy-gamma-43.vercel.app/',
  },
  {
    id: 5,
    title: 'Github repositories search',
    skills: 'React, Redux, Ant design',
    img: search_repos,
    gitHubLink: 'https://github.com/iuliana-lapatkina/search-repositories',
    gitPagesLink: 'https://iuliana-lapatkina.github.io/search-repositories/',
  },
  {
    id: 6,
    title: 'Tochka SPA',
    skills: 'React, Redux, Ant design',
    img: tochka,
    gitHubLink: 'https://github.com/iuliana-lapatkina/massage-spa',
    siteLink: 'https://tochka-spa.ru',
  },
  {
    id: 7,
    title: 'CPS Service',
    skills: 'React, Redux, Ant design',
    img: cps,
    gitHubLink: 'https://github.com/iuliana-lapatkina/CPS-project',
    vercelLink: 'https://iuliana-lapatkina.github.io/CPS-project/',
  },
  {
    id: 8,
    title: 'Dron Shop',
    skills: 'React, Redux, Ant design',
    img: drons,
    gitHubLink: 'https://github.com/iuliana-lapatkina/dron-shop',
  }
]

export {projects};