import React from 'react';

function Contacts() {
  return ( 
    <section className="section contacts">
        <div className="container">
          <h2 className="contacts__title section-title">Contacts</h2>
          <ul className="contacts__list">
            <li className="contacts__list-item">
              <h3 className="section-subtitle">Location</h3>
              <span className="contacts__text">Saint Petersburg, Russia</span>
            </li>
            <li className="contacts__list-item">
              <h3 className="section-subtitle">Telegram</h3>
              <a className="contacts__text" href="https://t.me/lapatkina_iuliana">
                @iuliana_lapatkina
              </a>
            </li>
            <li className="contacts__list-item">
              <h3 className="section-subtitle">Email</h3>
              <a className="contacts__text" href="mailto:iullap.spb@gmail.com">
                iullap.spb@gmail.com
              </a>
            </li>
          </ul>
        </div>
      </section>
   );
}

export default Contacts;