import React from 'react';
import ProjectItem from './projectItem/ProjectItem';
import { v4 as uuidv4 } from 'uuid';

import {projects} from './../../helpers/projectsList';

import './Projects.css';

function Projects() {
  return ( 
    <section className="section projects">
          <div className="container">
            <h2 className="section-title projects__title">Projects</h2>
            <ul className="projects__list">
              {projects.map((item) => {
                return <li key={uuidv4()}>
                  <ProjectItem project={item} />
                </li>
              })}
            </ul>
          </div>
        </section>
   );
}

export default Projects;