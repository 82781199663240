import React from 'react';

import { projects } from './../../helpers/projectsList';

import './Project.css';
import git from './img/gitHub-black.svg';
import vercel from './img/vercel.svg';

function Project(props) {
  const { id } = props;
  const project = projects.find((x) => x.id == id);

  return ( 
    <section className="section single-project">
      <div className="container single-project__container">
        <h2 className="section-title single-project__title">{project.title}</h2>
        <div className="single-project__image">
          <img src={project.img} alt={project.title} />
        </div>
        <span className="single-project__skills">
          Skills: {project.skills}
        </span>
        <div className="single-project__buttons">
          <a
            href={project.vercelLink}
            className="single-project__button
            single-project__versel"
            target="_blanc"
            rel="noreferrer"
          >
            <img src={vercel} alt="Ссылка на Vercel" />
            <span className="single-project__versel_text">Versel</span>
          </a>
          <a
            href={project.gitHubLink}
            className="single-project__button single-project__github"
            target="_blanc"
            rel="noreferrer"
          >
            <img src={git} alt="Ссылка на GitHub" />
            <span className="single-project__versel_text">GitHub repo</span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Project;
